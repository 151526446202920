<template>
  <div class="container">
    <img class="left-gif" src="@/assets/left-gif.gif" alt="Left GIF">
    <div class="content-container">
      <div class="text-container">
        <h1 :style="{ color: titleColor }" @mouseover="changeTitleColor">
          {OWLS}
        </h1>
        <p>Here comes the King of the Night!</p>
        <p>Flying with $OWLS, and owning this night!</p>
      </div>

      <p class="text1-container" @click="copyText">
        CA:AzQ7z5rU2gk4P8NYpQDoMhixyuQTvdTMg8aK9J7GWvrT
      </p>
      
      <button class="buy-button" @click="buyOwls">BUY</button>
    </div>

    <!-- Social Icons -->
    <div class="social-icons">
        <a href="https://x.com/OWLS_OvO_" target="_blank">
          <img src="@/assets/twitter-logo.png" alt="Twitter Logo" class="social-logo">
        </a>
        <a href="https://t.me/OWLS_OvO_SOL" target="_blank">
          <img src="@/assets/telegram-logo.png" alt="Telegram Logo" class="telegram-logo">
        </a>
    </div>

    </div>
  </template>
  
  <script>
  export default {
    name: 'PageContent',
    data() {
      return {
        titleColor: '#ff2fff', // 初始标题颜色
      };
    },
    methods: {
      buyOwls() {
        window.open('https://www.pump.fun/AzQ7z5rU2gk4P8NYpQDoMhixyuQTvdTMg8aK9J7GWvrT', '_blank');
      },
      copyText() {
        const text = 'AzQ7z5rU2gk4P8NYpQDoMhixyuQTvdTMg8aK9J7GWvrT';
        // 使用 document.execCommand 来进行复制
        const textArea = document.createElement('textarea');
        textArea.value = text;
        textArea.style.position = 'fixed';
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          const success = document.execCommand('copy');
          if (success) {
            alert('Copied');
          } else {
            console.error('Failed to copy');
          }
        } catch (err) {
          console.error('Failed to copy: ', err);
        }
        document.body.removeChild(textArea);
      },
      changeTitleColor() {
        // 生成随机颜色
        const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
        this.titleColor = randomColor;
      }
    }
  };
  </script>
  
  <style scoped>
  @font-face {
  font-family: 'CourierBOLD';
  src: url('@/assets/fonts/CourierBOLD.ttf') format('truetype');
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('@/assets/background.png');
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  padding: 20px;
}

.left-gif {
  max-width: 40%;
  height: auto;
}

.content-container {
  max-width: 50%;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

h1 {
  font-family: 'CourierBOLD';
  font-size: 4vw; /* 响应式字体大小 */
  color: #ff2fff;
  line-height: 1.2;
  letter-spacing: 7px;
  font-style: normal;
  margin: 0; /* 去除默认的外边距 */
}

p, .text1-container {
  font-family: 'CourierBOLD';
  font-size: 1.5vw; /* 响应式字体大小 */
  color: #ffffff;
  line-height: 1.5;
  margin: 10px 0;
  cursor: pointer; /* 光标变成手指形状，表示可点击 */
}

.text1-container:hover {
  text-decoration: underline; /* 鼠标悬停时添加下划线 */
}

.buy-button {
  width: 200px;
  height: 50px;
  background-color: #D0F400;
  color: #000000;
  border: none;
  border-radius: 50px;
  font-family: 'CourierBOLD';
  font-size: 30px;
  cursor: pointer;
  margin-top: 20px;
}

.buy-button:hover {
  background-color: #ffffff;
}

/* Social Icons Styles */
.social-icons {
  position: absolute;
  top: 50px;
  right: 50px;
  display: flex;
  gap: 15px; /* 图标之间的间距 */
}

.social-logo,
.telegram-logo {
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.social-logo:hover,
.telegram-logo:hover {
  transform: scale(1.5);
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .container {
    flex-direction: column;
  }

  .left-gif {
    width: 100%;
    height: auto;
  }

  .content-container {
    width: 100%;
    margin-left: 0;
    text-align: center;
    align-items: center;
  }

  h1 {
    font-size: 3vw; /* 调整字体大小以适应较小屏幕 */
  }

  p, .text1-container {
    font-size: 2vw; /* 调整字体大小以适应较小屏幕 */
  }

  .buy-button {
    width: 80%;
    font-size: 5vw;
  }
}

@media (max-width: 768px) {
  .left-gif {
    width: 100%;
  }

  h1 {
    font-size: 4vw; /* 进一步调整字体大小 */
  }

  p, .text1-container {
    font-size: 3vw; /* 进一步调整字体大小 */
  }

  .buy-button {
    width: 100%;
    font-size: 5vw;
  }
}

@media (max-width: 480px) {
  .container {
    flex-direction: column;
  }

  .left-gif {
    width: 100%;
    margin-bottom: 20px;
  }

  .content-container {
    width: 100%;
    text-align: center;
  }

  h1 {
    font-size: 5vw; /* 为更小的屏幕调整字体大小 */
  }

  p, .text1-container {
    font-size: 4vw; /* 为更小的屏幕调整字体大小 */
  }

  .buy-button {
    width: 100%;
    font-size: 6vw;
  }
}

  </style>
  