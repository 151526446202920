<template>
  <div id="app">
    <PageContent />
  </div>
</template>

<script>
import PageContent from './components/PageContent.vue';

export default {
  name: 'App',
  components: {
    PageContent
  }
};
</script>

<style>
/* 全局样式 */
body, html, #app {
  margin: 0;
  height: 100%;
}
</style>

